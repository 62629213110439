import axios, { resHandle } from "@/plugins/axios";

/**
 * @description 获得用户该项目下的数据表格
 * @param vCom Vue
 */
const GetUserDataTable = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("/api/tongue/tables").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获得用户该项目下的数据表格
 * @param vCom Vue
 */
const GetUserDataTable2 = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("/api/wajue/tables").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 定性特征分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostDingxingfenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/analysis/dxtz", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 颜色特征参数分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostYansefenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/analysis/ystz", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 几何特征参数分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostJihefenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/analysis/jhtz", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 纹理特征参数分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostWenlifenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/analysis/wltz", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 舌图演变规律分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostShetufenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/analysis/ybgl", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 统计分析：舌图演变规律分析: 参数
 * @param vCom Vue组件
 */
const Getargs = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/tongue/analysis/ybgl/args", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 预览数据表格
 * @param vCom Vue组件
 * @param tableId 表格id
 */
const GetDataTableReview = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/data/table", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获得专家经验挖掘的数据表格
 * @param vCom Vue
 */
const GetExpertDataTable = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/wajue/tables").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};

// 保存报告
const PostSaveResult = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/wajue/report", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 症状群分析
 * @param vCom Vue组件
 * @param params 参数
 */
const Postzhengzhuangqunfenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/wajue/zjbzjy/zzq2", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 症状特异性分析
 * @param vCom Vue组件
 * @param params 参数
 */
const Postzhengzhuangteyixingfenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/wajue/zjbzjy/zzty2", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 用药配伍分析
 * @param vCom Vue组件
 * @param params 参数
 */
const Postyongyaopeiwufenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/wajue/zjyyjy/yypw2", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 用药特异性分析
 * @param vCom Vue组件
 * @param params 参数
 */
const Postyongyaoteyixingfenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/wajue/zjyyjy/yyty2", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 随症用药规律
 * @param vCom Vue组件
 * @param params 参数
 */
const Postsuizhengyongyaoguilv = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/wajue/zjyyjy/szjj2", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获得专家经验挖掘指标
 * @param vCom Vue组件
 * @param tableId table_id
 */
const GetZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/wajue/zjbzjy/fzbl", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获得描述性分析的可选指标
 * @param vCom Vue组件
 * @param tableId table_id
 */
const GetMiaoshuxingfenxiZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/zdyfx/msxfx/xzzb", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
// 相关性分析
/**
 * @description 获得相关性分析的可选指标
 * @param vCom Vue组件
 * @param projectId project_id
 * @param tableId table_id
 */
const GetXiangguanxingfenxiZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/zdyfx/xgxfx/xzzb", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
// 关联规则分析
/**
 * @description 获得关联规则分析的可选指标
 * @param vCom Vue组件
 * @param projectId project_id
 * @param tableId table_id
 */
const GetGuanlianguizefenxiZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/zdyfx/glgz/xzzb", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
// 差异性分析
/**
 * @description 获得差异性分析-独立样本的可选指标
 * @param vCom Vue组件
 * @param projectId project_id
 * @param tableId table_id
 */
const GetChayijianyanDuliyangbenZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/zdyfx/test/dlyb/xzzb", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获得差异性分析-配对样本的可选指标
 * @param vCom Vue组件
 * @param projectId project_id
 * @param tableId table_id
 */
const GetChayijianyanPeiduiyangbenZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/zdyfx/test/pdyb/xzzb", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 下载数据表格
 * @param vCom Vue组件
 * @param tableId 表格id
 */
const GetExportDataTable = (filename: any) => {
    window.open(window.API_ROOT + "api/wajue/download?filename=" + filename);
};
/**
 * @description 描述性分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostMiaoshuxingfenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/zdyfx/msxfx", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 相关性分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostXiangguanxingfenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/zdyfx/xgxfx", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 关联规则分析
 * @param vCom Vue组件
 * @param params 参数
 */
const PostGuanlianguizefenxi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/zdyfx/glgz", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 差异性分析-独立样本
 * @param vCom Vue组件
 * @param params 参数
 */
const PostChayiduli = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/zdyfx/test/dlyb", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 差异性分析-配对样本
 * @param vCom Vue组件
 * @param params 参数
 */
const PostJiapeidui = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/zdyfx/test/pdyb", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                if (loading) {
                    loading.close();
                }
                reject();
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
export {
    GetUserDataTable, GetUserDataTable2, PostDingxingfenxi, PostYansefenxi, PostJihefenxi,
    PostWenlifenxi, PostShetufenxi, Getargs, GetDataTableReview, GetExpertDataTable,
    PostSaveResult, Postzhengzhuangqunfenxi, Postzhengzhuangteyixingfenxi,
    Postyongyaopeiwufenxi, Postyongyaoteyixingfenxi, Postsuizhengyongyaoguilv, GetZhibiao,
    GetMiaoshuxingfenxiZhibiao, GetXiangguanxingfenxiZhibiao, GetGuanlianguizefenxiZhibiao,
    GetChayijianyanDuliyangbenZhibiao, GetChayijianyanPeiduiyangbenZhibiao,
    GetExportDataTable, PostMiaoshuxingfenxi, PostXiangguanxingfenxi, PostGuanlianguizefenxi,
    PostChayiduli, PostJiapeidui
}