








































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Dingxing extends Vue {
  @Prop()
  private report: any;
}
