

















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import * as _ from "lodash";
@Component({})
export default class Yanse extends Vue {
  @Prop()
  private report: any;
  private tuType: any = "舌色";
  private xiangqingType: any = "舌色";
  private conclusionType: any = "舌色";
  private scatterOption: any = {
    grid3D: {
      boxWidth: 100,
      boxHeight: 100,
      viewControl: {
        projection: "orthographic",
        orthographicSize: 200,
      },
    },
    xAxis3D: {
      name: "l",
      type: "value",
      min: -10,
      max: 160,
      minInterval: 1,
    },
    yAxis3D: {
      name: "a",
      type: "value",
      min: 0,
      max: 160,
      minInterval: 1,
    },
    zAxis3D: {
      name: "b",
      type: "value",
      min: -5,
      max: 160,
      minInterval: 1,
    },
    series: [
      {
        type: "scatter3D",
        symbolSize: 12,
        itemStyle: {
          borderWidth: 1,
          borderColor: "rgba(255,255,255,0.8)",
        },
        emphasis: {
          itemStyle: {
            color: "#fff",
          },
          label: {
            show: true,
            formatter: (params: any) => {
              return `l:${params.value[0]};a:${params.value[1]};b:${params.value[2]}`;
            },
          },
        },
      },
    ],
  };
  private sandianCharts: any[] = [];
  /**
   * @description 创建3d散点图
   */
  private createScatter3D() {
    this.sandianCharts = [];
    this.report.report["颜色特征参数分布三维空间图"][this.tuType].forEach(
      (item: any) => {
        this.sandianCharts.push({
          HSV: this.createScatterOption(item["HSV"]),
          RGB: this.createScatterOption(item["RGB"]),
          LAB: this.createScatterOption(item["LAB"]),
          title: item.title,
          cState: "LAB",
        });
      }
    );
  }
  /**
   * @description 创建3d散点option
   */
  private createScatterOption(data: any) {
    const option = _.cloneDeep(this.scatterOption);
    let lmin = 200;
    let lmax = -100;
    let ltemp;
    let amin = 200;
    let amax = -100;
    let atemp;
    let bmin = 200;
    let bmax = -100;
    let btemp;
    option.series[0].data = data.map((item: any) => {
      ltemp = item[0];
      atemp = item[1];
      btemp = item[2];
      lmin = lmin <= ltemp ? lmin : ltemp;
      lmax = lmax >= ltemp ? lmax : ltemp;
      amin = amin <= atemp ? amin : atemp;
      amax = amax >= atemp ? amax : atemp;
      bmin = bmin <= btemp ? bmin : btemp;
      bmax = bmax >= btemp ? bmax : btemp;
      return {
        value: [item[0], item[1], item[2]],
        itemStyle: {
          color: item[3],
        },
      };
    });
    option.xAxis3D.min = Math.floor(lmin);
    option.xAxis3D.max = Math.ceil(lmax);
    option.yAxis3D.min = Math.floor(amin);
    option.yAxis3D.max = Math.ceil(amax);
    option.zAxis3D.min = Math.floor(bmin);
    option.zAxis3D.max = Math.ceil(bmax);
    return option;
  }
  /**
   * @description 改变图片类型
   */
  private changeTuTpye(type: any) {
    this.tuType = type;
    this.createScatter3D();
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.createScatter3D();
  }
}
