











































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetUserDataTable,
  PostDingxingfenxi,
  PostYansefenxi,
  PostJihefenxi,
  PostWenlifenxi,
  PostShetufenxi,
  Getargs,
} from "@/request/analysis";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import PreviewDialog from "../components/previewDialog.vue";
import Dingxing from "../components/dingxing.vue";
import Yanse from "../components/yanse.vue";
import Jihe from "../components/jihe.vue";
import Wenli from "../components/wenli.vue";
import Shetu from "../components/shetu.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Dingxing,
    Yanse,
    Jihe,
    Wenli,
    Shetu,
    PreviewDialog,
  },
})
export default class Name extends Vue {
  private config: any = {
    table_id: "",
    way: "定性",
    fenzubianliang: "",
  };
  private shetuConfig: any = {
    fenzu_id: "",
    fenzu_name: "",
    fenzu_value: "",
    fenxi_id: "",
    fenxi_name: "",
    fenxi_value: "",
  };
  private ifShowDataPreview: boolean = false;
  private tables: any[] = [];
  private projects: any[] = [];
  private results: any[] = [];
  private cResult: any = {};
  private shetuArgs: any = {};
  private fenzuOptions: any = [];
  private fenxiOptions: any = [];
  private get user() {
    return this.$store.state.user;
  }
  /**
   * @description 获得数据表格列表
   */
  private fetchTableData() {
    GetUserDataTable(this).then((data: any) => {
      this.tables = data;
    });
  }
  /**
   * @description 改变选择的表格
   */
  private tableIdChange() {
    if (this.config.way === "舌图") {
      this.shetuConfig = {
        fenzu_id: "",
        fenzu_name: "",
        fenzu_value: "",
        fenxi_id: "",
        fenxi_name: "",
        fenxi_value: "",
      };
      this.fenzuOptions = [];
      this.fenxiOptions = [];
      const params = {
        params: {
          table_id: this.config.table_id,
        },
      };
      Getargs(this, params).then((data: any) => {
        this.shetuArgs = data;
      });
    }
  }
  /**
   * @description 打开数据预览
   */
  private openDataPreview() {
    this.ifShowDataPreview = true;
  }
  /**
   * @description 舌图变量改变
   */
  private bianliangChange(item: any, name: any) {
    this.shetuArgs[item].forEach((ele: any) => {
      if (ele.name === name) {
        if (item === "分组变量") {
          this.fenzuOptions = ele.values;
          this.shetuConfig.fenzu_id = ele.id;
          this.shetuConfig.fenzu_value = "";
        } else {
          this.fenxiOptions = ele.values;
          this.shetuConfig.fenxi_id = ele.id;
          this.shetuConfig.fenxi_value = "";
        }
      }
    });
  }
  /**
   * @description 选择统计分析方法
   */
  private chooseWay(item: any) {
    this.config = {
      table_id: "",
      way: item,
      fenzubianliang: "",
    };
    this.shetuConfig = {
      fenzu_id: "",
      fenzu_name: "",
      fenzu_value: "",
      fenxi_id: "",
      fenxi_name: "",
      fenxi_value: "",
    };
    this.fenzuOptions = [];
    this.fenxiOptions = [];
    this.$router.replace({
      path: "/main/dig/tongue-result",
      query: {
        type: item,
      },
    });
  }
  /**
   * @description 开始分析
   */
  private startAnalysis() {
    if (!this.config.table_id) {
      this.$message.warning("请选择一个数据表格！");
      return;
    }
    switch (this.config.way) {
      case "定性":
        this.dingxingFenxi();
        break;
      case "颜色":
        this.yanseFenxi();
        break;
      case "几何":
        this.jiheFenxi();
        break;
      case "纹理":
        this.wenliFenxi();
        break;
      case "舌图":
        this.shetuFenxi();
        break;
    }
  }
  /**
   * @description 定性特征分析
   */
  private dingxingFenxi() {
    if (!this.config.fenzubianliang) {
      this.$message.warning("请选择一个分组变量！");
      return;
    }
    const params = {
      table_id: this.config.table_id,
      分组变量: this.config.fenzubianliang,
    };
    PostDingxingfenxi(this, params).then((data: any) => {
      const result: any = {
        title: "定性特征分析",
        report: data,
        _id: new Date().getTime(),
      };
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }
  /**
   * @description 颜色特征参数分析
   */
  private yanseFenxi() {
    if (!this.config.fenzubianliang) {
      this.$message.warning("请选择一个分组变量！");
      return;
    }
    const params = {
      table_id: this.config.table_id,
      分组变量: this.config.fenzubianliang,
    };
    PostYansefenxi(this, params).then((data: any) => {
      const result: any = {
        title: "颜色特征参数分析",
        report: data,
        _id: new Date().getTime(),
      };
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }
  /**
   * @description 几何特征参数分析
   */
  private jiheFenxi() {
    if (!this.config.fenzubianliang) {
      this.$message.warning("请选择一个分组变量！");
      return;
    }
    const params = {
      table_id: this.config.table_id,
      分组变量: this.config.fenzubianliang,
    };
    PostJihefenxi(this, params).then((data: any) => {
      const result: any = {
        title: "几何特征参数分析",
        report: data,
        _id: new Date().getTime(),
      };
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }
  /**
   * @description 纹理特征参数分析
   */
  private wenliFenxi() {
    if (!this.config.fenzubianliang) {
      this.$message.warning("请选择一个分组变量！");
      return;
    }
    const params = {
      table_id: this.config.table_id,
      分组变量: this.config.fenzubianliang,
    };
    PostWenlifenxi(this, params).then((data: any) => {
      const result: any = {
        title: "纹理特征参数分析",
        report: data,
        _id: new Date().getTime(),
      };
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }
  /**
   * @description 舌图演变规律分析
   */
  private shetuFenxi() {
    if (!this.shetuConfig.fenzu_name) {
      this.$message.warning("请选择一个分组变量！");
      return;
    }
    if (!this.shetuConfig.fenxi_name) {
      this.$message.warning("请选择一个分析变量！");
      return;
    }
    if (!this.shetuConfig.fenxi_value) {
      this.$message.warning("请选择一个分析变量参数！");
      return;
    }
    const params = {
      table_id: this.config.table_id,
      分组变量: {
        id: this.shetuConfig.fenzu_id,
        name: this.shetuConfig.fenzu_name,
        value: this.shetuConfig.fenzu_value,
      },
      分析变量: {
        id: this.shetuConfig.fenxi_id,
        name: this.shetuConfig.fenxi_name,
        value: this.shetuConfig.fenxi_value,
      },
    };
    PostShetufenxi(this, params).then((data: any) => {
      const result: any = {
        title: "舌图演变规律分析",
        report: data,
        _id: new Date().getTime(),
      };
      this.results.push(result);
      this.cResult = this.results[this.results.length - 1];
    });
  }

  /**
   * @description 改变当前的报告
   */
  private changeResult(result: any) {
    this.cResult = result;
  }
  /**
   * @description 删除一份报告
   */
  private deleteOneResult(index: number, result: any) {
    if (result === this.cResult) {
      this.results.splice(index, 1);
      if (this.results.length > 0) {
        this.cResult = this.results[0];
      }
    } else {
      this.results.splice(index, 1);
    }
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.config.way = this.$route.query.type;
    if (this.$route.query) {
      if (this.$route.query.way) {
        this.chooseWay(this.$route.query.way);
      }
    }
    this.fetchTableData();
  }
}
