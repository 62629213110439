












































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Jihe extends Vue {
  @Prop()
  private report: any;
  private explainType: any = "苔质";
  private conclusionType: any = "苔质";
}
