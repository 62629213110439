import { render, staticRenderFns } from "./shetu-result.vue?vue&type=template&id=146b8276&scoped=true&"
import script from "./shetu-result.vue?vue&type=script&lang=ts&"
export * from "./shetu-result.vue?vue&type=script&lang=ts&"
import style0 from "./shetu-result.vue?vue&type=style&index=0&id=146b8276&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146b8276",
  null
  
)

export default component.exports