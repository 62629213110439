









































































import { Component, Vue, Prop } from "vue-property-decorator";
import * as _ from "lodash";
@Component({})
export default class Shetu extends Vue {
  @Prop()
  private report: any;
  private scatterOption: any = {
    title: {
      left: "center",
    },
    grid: {},
    xAxis: {
      type: "category",
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "scatter",
      },
    ],
  };
  private l: any = {};
  private a: any = {};
  private b: any = {};
  private get type() {
    return this.report["report"]["分析变量"].split("-")[0];
  }
  private get thead() {
    return this.report["report"]["分布详情"]["title"];
  }
  private get tbody() {
    return this.report["report"]["分布详情"]["value"];
  }
  /**
   * @description 颜色特征参数
   */
  private createYanse() {
    this.l = _.cloneDeep(this.scatterOption);
    this.l.title.text = this.report["report"]["分组变量"];
    this.l.yAxis.name = this.report["report"]["演变规律图"][0]["y_name"];
    this.l.xAxis.data = this.report["report"]["演变规律图"][0]["name"];
    this.l.series[0].data = this.report["report"]["演变规律图"][0]["data"];
    this.a = _.cloneDeep(this.scatterOption);
    this.a.title.text = this.report["report"]["分组变量"];
    this.a.yAxis.name = this.report["report"]["演变规律图"][1]["y_name"];
    this.a.xAxis.data = this.report["report"]["演变规律图"][1]["name"];
    this.a.series[0].data = this.report["report"]["演变规律图"][1]["data"];
    this.b = _.cloneDeep(this.scatterOption);
    this.b.title.text = this.report["report"]["分组变量"];
    this.b.yAxis.name = this.report["report"]["演变规律图"][2]["y_name"];
    this.b.xAxis.data = this.report["report"]["演变规律图"][2]["name"];
    this.b.series[0].data = this.report["report"]["演变规律图"][2]["data"];
  }
  /**
   * @description 几何特征参数
   */
  private createJihe() {
    this.a = _.cloneDeep(this.scatterOption);
    this.a.title.text = this.report["report"]["分组变量"];
    this.a.yAxis.name = this.report["report"]["演变规律图"][0]["y_name"];
    this.a.xAxis.data = this.report["report"]["演变规律图"][0]["name"];
    this.a.series[0].data = this.report["report"]["演变规律图"][0]["data"];
    this.b = _.cloneDeep(this.scatterOption);
    this.b.title.text = this.report["report"]["分组变量"];
    this.b.yAxis.name = this.report["report"]["演变规律图"][1]["y_name"];
    this.b.xAxis.data = this.report["report"]["演变规律图"][1]["name"];
    this.b.series[0].data = this.report["report"]["演变规律图"][1]["data"];
  }
  /**
   * @description 定性特征参数
   */
  private createDingxing() {
    this.a = _.cloneDeep(this.scatterOption);
    this.a.title.text = this.report["report"]["分组变量"];
    this.a.yAxis.name = this.report["report"]["演变规律图"][0]["y_name"];
    this.a.xAxis.data = this.report["report"]["演变规律图"][0]["name"];
    this.a.series[0].data = this.report["report"]["演变规律图"][0]["data"];
  }
  /**
   * @description 纹理特征参数
   */
  private createWenli() {
    this.a = _.cloneDeep(this.scatterOption);
    this.a.title.text = this.report["report"]["分组变量"];
    this.a.yAxis.name = this.report["report"]["演变规律图"][0]["y_name"];
    this.a.xAxis.data = this.report["report"]["演变规律图"][0]["name"];
    this.a.series[0].data = this.report["report"]["演变规律图"][0]["data"];
  }
  private mounted() {
    switch (this.type) {
      case "颜色特征参数":
        this.createYanse();
        break;
      case "几何特征参数":
        this.createYanse();
        break;
      case "定性特征参数":
        this.createDingxing();
        break;
      case "纹理特征参数":
        this.createWenli();
        break;
    }
  }
}
